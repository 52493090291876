require.undef('buildTemplateInitializer');
define('buildTemplateInitializer', ['viewData', 'jquery', 'templateForm', 'templateData', 'perspectiveHotspotPreview', 'review', 'loadedTemplates'],
    function (viewData, $, templateForm, templateData, perspectiveHotspotPreview, review, loadedTemplates) {
        'use strict';

        var zoomViewSelector = '.preview-container .zoom-view';
        var perspectiveHotspotPreviewTimer = 0;
        var reviewTimer = 0;
        var pageLoad = true;


        var initializeBuildTemplate = function () {
            var savedBuild = viewData.SavedBuild;

            if (!savedBuild)
                return;

            $('.project-ui').addClass('build-template');

            // initialize the hotspots in the saved build
            savedBuild.Hotspots.forEach(function (hotspot) {
                initializeHotspot(hotspot);
            });


            // not the ideal way to do this.  Waiting for all hotspots field to load on page load.  Then apply the disabled hotspot feature
            $(document).ajaxStop(function () {
                if (pageLoad) {
                    //get hotspot ids to disable
                    var hotspotsToDisable = [];
                    // get all hotspots from disabled perspective.  ---------> Disabling perspectives was removed per Becca.  2-21-18
                    for (var i = 0; i < savedBuild.DisabledPerspectives.length; i++) {
                        for (var j = 0; j < viewData.Project.Perspectives.length; j++) {
                            if (savedBuild.DisabledPerspectives[i] == viewData.Project.Perspectives[j].ID) {
                                for (var k = 0; k < viewData.Project.Perspectives[j].Hotspots.length; k++) {
                                    hotspotsToDisable.push(viewData.Project.Perspectives[j].Hotspots[k].ID);
                                }
                            }
                        }
                    }
                    // get all disabled hotspot IDs
                    for (var i = 0; i < savedBuild.DisabledHotspots.length; i++) {
                        hotspotsToDisable.push(savedBuild.DisabledHotspots[i]);
                    }
                    var uniqueHotspotIDs = hotspotsToDisable.filter(function (elem, index, self) {
                        return index === self.indexOf(elem);
                    })

                    hotspotsToDisable.forEach(function (hotspotId) {
                        DisableHotspot(hotspotId);
                    });
                    pageLoad = false;
                }
            });

        };


        var DisableHotspot = function (hotspotId) {
            var $hotspot = $('.hotspot-view[data-id=' + hotspotId + ']');
            $hotspot.find('.menu-tab').removeClass('locked-in').addClass('locked-out');
            var $hotSpotField = $hotspot.find('.template-group-content .field');
            $hotSpotField.each(function (o, i) {
                var $this = $(this);
                $this.find('.field-option-text-control').addClass('read-only');
                $this.find('.field-option-color-control').addClass('read-only');
                $this.find('input').attr('readonly', 'readonly');
            });

            var $disabledOverlay = $('<div>').addClass('disabled-hotspot-overlay');
            var $image = $('<img>').attr('src', '/images/lock.png');
            $disabledOverlay.append($image);
            var $subCopyLarge = $('<div>').addClass('sub-copy-large').html('This location is locked');
            var $subCopySmall = $('<div>').addClass('sub-copy-small').html('Your department manager has locked this location. ');
            var $clickText = $('<span>').html(' Click or tap</span>').addClass('click').click(zoomOutLinkClicked);
            var $remainingText = $('<span>').html(' to go back to the desgin.');
            $subCopySmall.append($clickText).append($remainingText)
            $disabledOverlay.append($subCopyLarge).append($subCopySmall);
            $hotspot.append($disabledOverlay);
        }



        // zoom out link click handler
        var zoomOutLinkClicked = function () {
            $('.zoom-view .zoom-out-link').click();
        };


        var initializeHotspot = function (hotspot) {

            // get the hotspot element
            var $hotspot = $('.hotspot-view[data-id=' + hotspot.ID + ']');

            // get the hotspot preview element
            var $hotspotPreview = $('.perspective-view .hotspot[data-hotspotid=' + hotspot.ID + ']');
            $hotspotPreview.addClass('build-template-related');

            // get the template group
            var $templateGroup = $hotspot.find('.template-group[data-id=' + hotspot.TemplateGroupID + ']');

            // mark the hotspot applied
            $templateGroup.parents('.hotspot-view').addClass('applied build-template-related tabcount-1').removeClass('tabcount-2 tabcount-3 tabcount-4 tabcount-5 tabcount-6');

            // activate the appropriate tab
            $templateGroup.parents('.menu-tab').addClass('open applied locked-in');

            // lock out sibling tabs
            $templateGroup.parents('.menu-tab').siblings('.menu-tab').remove();

            // activate the appropriate template group
            $templateGroup.addClass('open').addClass('open applied locked-in');

            // lock out sibling template groups
            $templateGroup.siblings().remove();

            // hide the other template groups
            $templateGroup.parents('.menu-tab-content').addClass('num-groups-1').removeClass('num-groups-2 num-groups-3 num-groups-4 num-groups-5 num-groups-6 num-groups-7 num-groups-8 num-groups-9 num-groups-10');

            // get the template form container
            var $templateFormContainer = $templateGroup.find('.template-form');

            // load the templates
            templateData.getTemplate(hotspot.Template.ID).done(function (template) {

                // load the template into the library
                loadedTemplates.loadTemplate(template);

                // initialize template form
                templateForm.initializeForm($templateFormContainer, template);

                // change the template button text
                var $templateSelectButton = $templateGroup.find('.template-button-container .select-template-button');
                $templateSelectButton.html('Change ' + $templateSelectButton.attr('data-label'));

                // remove the overlay from the previews
                var $zoomView = $(zoomViewSelector);
                $zoomView.removeClass('customizing').addClass('customizing');

                // initialize the saved build values
                applySavedBuildValuesToForm($templateFormContainer, hotspot);

                // request the previews for the hotspots in the perspective preview
                showPerspectiveHotspotPreviews();

                // initialize the review section
                refreshReviewSection();


                // if there is at least 1 hotspot applied than show the review content instead of the project help text
                if ($('.hotspot-content-container .hotspot-view.applied').length > 0) {
                    $('.menu .project-help-text').addClass('hide');
                    $('.menu .review-container').removeClass('hide');
                }
            });
        };








        var applySavedBuildValuesToForm = function ($formContainer, hotspot) {
            hotspot.Fields.forEach(function (field) {
                var $field = $formContainer.find('.field[data-id=' + field.UUID + ']');
                if (field.ActiveOptionUUID)
                    $field.find('.field-option-select').val(field.ActiveOptionUUID).change().parents('.option-select-dropdown-container').addClass('read-only');
                var $fieldOption = $field.find('.field-options .field-option[data-id=' + field.ActiveOptionUUID + ']');
                if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(field.Type) != -1) {	// text field type
                    if (!field.AllowFontChoice) {
                        if (field.SelectedOption.ActiveFormatUUID)
                            $fieldOption.find('.text-format-select').val(field.SelectedOption.ActiveFormatUUID).change().parents('.format-select-dropdown-container').addClass('read-only');
                    }
                    if (field.Text) {
                        $fieldOption.find('.Text-field .customizable-item-wrapper').addClass('active');
                        $fieldOption.find('.Text-field').addClass('read-only').find('input.field-option-text-input').val(field.Text).prop('readonly', true).change();
                    }
                    if (field.ColorID)
                        $fieldOption.find('.Color-field').addClass('read-only').find('input').val(field.ColorID).prop('readonly', true).change();
                    if (field.OutlineColorID)
                        $fieldOption.find('.OutlineColor-field').addClass('read-only').find('input').val(field.OutlineColorID).prop('readonly', true).change();
                    if (field.ShadowColorID)
                        $fieldOption.find('.ShadowColor-field').addClass('read-only').find('input').val(field.ShadowColorID).prop('readonly', true).change();
                }
                else if (field.Type == 1) {	// image field type
                    if (field.ImageID) {
                        $field.addClass('saved-build-first-view');
                        $fieldOption.find('.Image-field').addClass('read-only').find('input').val(field.ImageID).prop('readonly', true).change();
                    }

                }
                else if (field.Type == 11) {	// custom select options - radiobutton list or checkbox group
                    for (var i = 0; i < field.SelectedOptions.length; i++) {
                        $fieldOption.find('.CustomSelectOptions-field input[data-sku="' + field.SelectedOptions[i].Sku + '"]').prop("checked", true).change();
                    }
                    if (field.SelectedOptions.length > 0) {
                        $fieldOption.find('.CustomSelectOptions-field input').addClass('read-only').prop('disabled', true).change();
                    }
                }
                else if (field.Type === 12) {// custom image         
                    $fieldOption.find('.custom-image-content-wrapper input.custom-image-id').val(field.CustomImage.CloudinaryId);
                    $fieldOption.find('.custom-image-content-wrapper input.custom-image-version').val(field.CustomImage.Version);
                }

                else if (field.Type === 15) {// // salesperson/NS sku   
                    // sku 1
                    $fieldOption.find('.NS-Sku1-Description').html(field.NetSuiteSalesPersonData.Sku1Description);
                    $fieldOption.find('.NS-Sku1-Description-Hidden').html(field.NetSuiteSalesPersonData.Sku1Description);
                    $fieldOption.find('.NS-Sku1-Price').val(field.NetSuiteSalesPersonData.Sku1Price);
                    $fieldOption.find('.NS-Sku1-Price-Hidden').val(field.NetSuiteSalesPersonData.Sku1Price);
                    $fieldOption.find('.NS-Sku1-Cost').val(field.NetSuiteSalesPersonData.Sku1Cost);
                    $fieldOption.find('.NS-Sku1-Cost-Hidden').val(field.NetSuiteSalesPersonData.Sku1Cost);
                    $fieldOption.find('.NS-Sku1-Hidden').val(field.NetSuiteSalesPersonData.Sku1);
                    $fieldOption.find('.NS-Sku1').val(field.NetSuiteSalesPersonData.Sku1);
                    $fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');

                    // sku 2
                    $fieldOption.find('.netSuite-sku2-option-wrapper').removeClass('hide');
                    if (field.NetSuiteSalesPersonData.Sku2 != null && field.NetSuiteSalesPersonData.Sku2 != '') {
                        $fieldOption.find('#NetSuite-Sku2-Option').click();
                        $fieldOption.find('.NS-Sku2-Description').html(field.NetSuiteSalesPersonData.Sku2Description);
                        $fieldOption.find('.NS-Sku2-Description-Hidden').html(field.NetSuiteSalesPersonData.Sku2Description);
                        $fieldOption.find('.NS-Sku2-Price').val(field.NetSuiteSalesPersonData.Sku2Price);
                        $fieldOption.find('.NS-Sku2-Price-Hidden').val(field.NetSuiteSalesPersonData.Sku2Price);
                        $fieldOption.find('.NS-Sku2-Cost').val(field.NetSuiteSalesPersonData.Sku2Cost);
                        $fieldOption.find('.NS-Sku2-Cost-Hidden').val(field.NetSuiteSalesPersonData.Sku2Cost);
                        $fieldOption.find('.NS-Sku2-Hidden').val(field.NetSuiteSalesPersonData.Sku2);
                        $fieldOption.find('.NS-Sku2').val(field.NetSuiteSalesPersonData.Sku2);
                        $fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');
                    } else {
                        $fieldOption.find('.NS-Sku2-Cost').val('');
                    }
                }
            });
            // Notes field
            if (hotspot.Notes != undefined && hotspot.Notes != null && hotspot.Notes != '') {
                $formContainer.find('.field-notes textarea').attr('readonly', 'readonly').text(hotspot.Notes).addClass('read-only');
            }
        };

        var showPerspectiveHotspotPreviews = function () {
            clearTimeout(perspectiveHotspotPreviewTimer);
            perspectiveHotspotPreviewTimer = setTimeout(function () {
                perspectiveHotspotPreview.showPerspectiveHotspotPreviews();
            }, 700);
        };

        var refreshReviewSection = function () {
            clearTimeout(reviewTimer);
            reviewTimer = setTimeout(function () {
                review.refreshReviewUI();
            }, 700);
        };

        return {
            initializeBuildTemplate: initializeBuildTemplate
        };
    });
require.undef('templateData');
define('templateData', ['http'], function (http) {
	'use strict';

	var getTemplatesForHotspotTemplateGroup = function (id) {
		var params = {
			templateGroupID: id
		};
		return http.get('/Build/BuildTemplateCustomization/TemplateList', params).then(processResponse);
	};

	var getTemplate = function (id) {
		var params = {
			id: id
		};
		return http.get('/Build/BuildTemplateCustomization/TemplateDetails', params).then(processResponse);
	};

	var processResponse = function (response) {
		return response.Data;
	};

	return {
		getTemplatesForHotspotTemplateGroup: getTemplatesForHotspotTemplateGroup,
		getTemplate: getTemplate
	};
});
require(['perspectiveListing',
		'perspectivePreview',
		'hotspotPreview',
		'menu',
		'templateForm',
		'review',
		'templateGroups',
		'loadedTemplates',
		'perspectiveHotspotPreview',
		'imageSelectorFieldControl',
		'applyHotspot',
		'buildTemplateInitializer',
		'addToCart',
        'updateUI',
        'overlay'], function
		(perspectiveListing,
		perspectivePreview,
		hotspotPreview,
		menu,
		templateForm,
		review,
		templateGroups,
		loadedTemplates,
		perspectiveHotspotPreview,
		imageSelectorFieldControl,  
		applyHotspot,
		buildTemplateInitializer,
        addToCart,
        updateUI,
        overlay) {
	'use strict';

    var init = function () {
        // add overlay to prevent unwanted clicks
        overlay.addOverlay();
        setTimeout(function () {
            overlay.removeOverlay();
        }, 2000);


		// run init functions for relevant modules
		review.init();
		perspectivePreview.init();
		menu.init();	
		hotspotPreview.init();
		templateGroups.init();
		templateForm.init();
		loadedTemplates.init();
		perspectiveHotspotPreview.init();
		imageSelectorFieldControl.init();
		applyHotspot.init();
		addToCart.init();

		// run initializeUI functions for relevant modules
		perspectiveListing.initializeUI();
		perspectivePreview.initializeUI();

		// initialize the saved build
		buildTemplateInitializer.initializeBuildTemplate();

        review.refreshReviewUI();
        updateUI.init();            
	};

	init();
});